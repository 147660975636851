import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import CvModal from './cv-modal';

gsap.registerPlugin(ScrollTrigger);

const AboutSection = ({ darkMode }) => {
  const containerRef = useRef(null);
  const linesRef = useRef([]);
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const lines = linesRef.current;
  
    // Set up intersection observer for scroll animations
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    
    // GSAP Animation
    gsap.to(lines, {
      opacity: 1,
      stagger: 0.2, // Stagger the opacity increase
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        markers: false
      }
    });
    
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      setIsVisible(false);
    };
  }, []);

  
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });
    
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);
  const yOffset = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [100, 0, 0, 100]);

  // Split text into lines for individual opacity control
  const textLines = [
    "With seven years in design and six years in UX, I bring a strong visual and user-centered approach to ",
    "development. While I’ve worked with HTML and CSS for years, in 2022, I shifted to coding full-scale ",
    "applications from scratch—focusing on React, state management, and modern UI frameworks. I build ",
    "responsive, performant web experiences with clean, maintainable code while prioritizing usability, ",
    "accessibility, and optimization.",

  ];

  const handleOpenCV = () => {
    setIsModalOpen(true);
  };

  const handleCloseCV = () => {
    setIsModalOpen(false);
  };

  return (
    <div 
    id="about" 
      ref={containerRef}
      className={`py-20 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-800'} relative w-full py-16 px-4`}
    >
      <div className="max-w-4xl mx-auto">
        {/* Title and Subtitle - Now centered */}
        <motion.div 
          className="mb-12 text-center relative"
          style={{ opacity, y: yOffset }}
        >
          <motion.h2 
            className="text-6xl md:text-8xl font-black mb-3 tracking-tight tracking-wider text-center"
            initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)" }}
            whileInView={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <div className={`${darkMode ? 'text-white' : 'text-gray-900'} tracking-[0.2em] font-light mb-0`}>
                ABOUT
                </div>

            <span className="text-blue-500 ml-4"></span>
          </motion.h2>
         
          <motion.p 
            className={`text-xl md:text-2xl max-w-xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'} font-light text-center`}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.7 }}
          >
            
          </motion.p>
        </motion.div>

        <div className={`${darkMode ? 'text-white' : 'text-gray-900'} tracking-[0.2em] font-light mb-0 text-2xl font-light space-y-2 max-w-4xl mx-auto text-center`}>      
        
          {textLines.map((line, index) => (
            <p
              key={index}
              ref={el => linesRef.current[index] = el}
              className="opacity-20"
            >
              {line}
            </p>
          ))}
        </div>
        <button 
           onClick={handleOpenCV}
          className="mt-12 px-4 py-2 w-40 mx-auto block rounded-full border-blue-600 border-2 text-blue-600 bg-transparent hover:bg-gray-100 transition-colors"
        >
          Show CV
        </button>
      </div>
       {/* Using the ProjectModal component */}
       <AnimatePresence>
  {isModalOpen && (
    <CvModal
      onClose={handleCloseCV} 
      isOpen={isModalOpen}
      darkMode={darkMode}
    />
  )}
</AnimatePresence>
    </div>
    
  );
};

export default AboutSection;