import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Menu } from 'lucide-react';
import Skills from './design-skills';
import Intersection from './diagram';


gsap.registerPlugin(ScrollTrigger);

const StudioLayout = ({ showHeader = true }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const parallaxRef = useRef(null);

  // Function to handle smooth scrolling using your existing setup
  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false); // Close menu after navigation
  };
 
  const scrollToWorks = () => {
    const worksSection = document.getElementById('latest-works');
    if (worksSection) {
      worksSection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const scrollToContact = () => {
    const worksSection = document.getElementById('contact');
    if (worksSection) {
      worksSection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  useEffect(() => {
    // Set up the parallax effect with GSAP ScrollTrigger
    const parallaxElements = parallaxRef.current.querySelectorAll('.parallax-element');
    
    parallaxElements.forEach((element) => {
      gsap.to(element, {
        y: '-30%',
        ease: 'none',
        scrollTrigger: {
          trigger: aboutRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,
          markers: false,
          toggleActions: 'play none none none'
        }
      });
    });
  
    
    const heroTextElements = document.querySelectorAll('.hero-text');
    
   
    gsap.set(heroTextElements, { 
      opacity: 0, 
      y: 40,
      color: '#000000'
    });
  
   
    const heroTimeline = gsap.timeline({
      defaults: {
        ease: "power3.out"
      }
    });
    
  
    heroTextElements.forEach((element, index) => {
      heroTimeline.to(element, {
        opacity: 1,
        y: 0,
        duration: 1.2,
        delay: index * 0.15, 
      }, index * 0.2); 
    });
    
    
    gsap.from(heroRef.current, {
      scale: 1.03,
      duration: 2,
      ease: "power2.out"
    });
  
    
    const aboutTextElements = document.querySelectorAll('.about-text');
    aboutTextElements.forEach((element) => {
      
      gsap.set(element, { opacity: 1, y: 0 });

      gsap.from(element, {
        opacity: 0,
        y: 30,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: element,
          start: 'top 90%', 
          toggleActions: 'play none none reset', 
          markers: false
        },
        clearProps: 'all'
      });
    });
  
    // Polyfill for scroll behavior to support older browsers
    if ('scrollBehavior' in document.documentElement.style === false) {
      document.documentElement.style.scrollBehavior = 'auto';
    }
    
  }, []);

  return (
    <div className="font-sans text-black">
      {/* Hero Section */}
      <section ref={heroRef} className="relative min-h-screen bg-white overflow-hidden">
        {/* Navigation */}
        <div className="flex justify-between items-center p-4 md:p-8 max-w-screen-xl mx-auto">
          <h1 className="text-xl font-medium">Ariane Souza*</h1>
          
          {/* New Navigation Component */}
          <div className="flex items-center">
            {/* Desktop navigation */}
            <div className="hidden md:flex items-center flex-wrap gap-4">
              <button 
                onClick={() => handleScroll('about')} 
                className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
              >
                About
              </button>
              <button 
                onClick={() => handleScroll('latest-works')} 
                className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
              >
                Works
              </button>
              <button 
                onClick={() => handleScroll('services')} 
                className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
              >
                Services
              </button>
              <button 
                onClick={() => handleScroll('testimonials')} 
                className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
              >
                Clients
              </button>
              <button 
                onClick={() => handleScroll('behance')} 
                className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
              >
               Archive
              </button>
              <button 
                onClick={() => handleScroll('contact')} 
                className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
              >
                Contact
              </button>
              <a href="https://www.arianesouza.com" target="_blank" rel="noopener noreferrer">
                <button className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors">
                  Dev. Core
                </button>
              </a>
            </div>
            
            {/* Mobile navigation */}
            <div className="flex items-center md:hidden">
              <a href="https://www.arianesouza.com" target="_blank" rel="noopener noreferrer" className="mr-4">
                <button className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors">
                  Dev. Core
                </button>
              </a>
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-md border border-black"
              >
                <Menu size={20} />
              </button>
            </div>
          </div>
          
          {/* Mobile menu drawer */}
          {isMenuOpen && (
            <div className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-md z-50 py-4 px-6">
              <div className="flex flex-col space-y-4">
                <button 
                  onClick={() => handleScroll('about')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                >
                  About
                </button>
               
                <button 
                  onClick={() => handleScroll('latest-works')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                >
                  Works
                </button>
                <button 
                  onClick={() => handleScroll('services')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                >
                  Services
                </button>
                <button 
                  onClick={() => handleScroll('testimonials')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                >
                  Testimonials
                </button>
                <button 
                  onClick={() => handleScroll('contact')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                >
                  Contacts
                </button>
                <button 
                  onClick={() => handleScroll('behance')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                >
                  Archive
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Hero Content - Updated with consistent margins */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 p-6 mt-12 max-w-screen-xl mx-auto">
          <div className="md:col-span-7 md:col-start-1">
            {/* Hero Content - Improved Responsive Typography */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 p-6 mt-12 max-w-screen-xl mx-auto">
              <div className="md:col-span-7 md:col-start-1">
                <div className="space-y-2">
                  <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold hero-text text-black leading-tight">I AM</h2>
                  <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold hero-text text-black leading-tight">ARIANE*</h2>
                  <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold hero-text text-black leading-tight">UX/UI DESIGNER</h2>
                  <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold hero-text text-black leading-tight"> & DEVELOPER</h2>
                </div>
              </div>
            </div>
            <div className="mt-24 md:mt-36">
              <button onClick={scrollToWorks} className="flex items-center space-x-2 text-black hover:text-gray-600 transition-colors">
                <span className="h-2 w-2 bg-black rounded-full"></span>
                <span className="text-lg">My featured UX projects →</span>
              </button>
            </div>
          </div>

          <div className="md:col-span-5 md:col-start-8">
            <div className="relative h-64 md:h-96 w-full">
              <div className="absolute right-0 top-0 bg-yellow-400 h-full w-full rounded-lg">
                {/* Replaced visual elements with image placeholder */}
                <img src="/images/heroo-image.png" alt="Hero Image Placeholder" className="w-full h-full object-cover  rounded-lg" />
              </div>
            </div>

            <div className="mt-8 text-right">
            <p className="text-lg">Based in the global city of Sao Paulo</p>
            <p className="text-lg">Finding joy in connecting with people worldwide</p>
            <p className="text-lg">Crafting bold design for awesome brands</p>
          </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" ref={aboutRef} className="min-h-screen bg-white relative">
        <div ref={parallaxRef} className="grid grid-cols-1 md:grid-cols-12 gap-8 p-6 max-w-screen-xl mx-auto">
          <div className="md:col-span-8 md:col-start-1">
            <h2 className="text-5xl md:text-7xl font-bold mb-8 mt-16 about-text">
              MY PASSION LIES IN CREATING VISUAL EXPERIENCES THAT CAPTIVATE AND INSPIRE
            </h2>

            <div className="mt-16 about-text">
              <button  onClick={scrollToContact} className="flex items-center space-x-2 text-black hover:text-gray-600 transition-colors">
                <span className="h-2 w-2 bg-black rounded-full"></span>
                <span className="text-lg">Let's work together →</span>
          
              </button>
            </div>
          
            

            <div className="mt-32 about-text">
              <p className="uppercase text-sm font-medium mb-6">SINCE /'19</p>
              <p className="max-w-2xl text-lg leading-relaxed">
              I don’t just design — I solve. I started my career as an Art Director, 
              leading visual campaigns and creative teams across digital, editorial, and motion projects. 
              It was a role that sharpened my eye for storytelling, branding, and communication.
              </p>
              <br />
              <p className="max-w-2xl text-lg leading-relaxed"> Along the way, I became particularly interested in how people interact with design — especially on 
              a psychological level. That interest led me into UX/UI, where for the past 6 years I’ve combined design 
              thinking, user research, and product strategy to create experiences that are as intuitive as they are impactful.</p>
              <br />
              <p className="max-w-2xl text-lg leading-relaxed"> Over the past 5 years, I’ve also brought those ideas to life through code. 
                From wireframes to fully functional interfaces, I’ve worked with technologies like React, JavaScript, and Node.js to create seamless, scalable solutions.</p>
                <br />
                <p className="max-w-2xl text-lg leading-relaxed"> My background in storytelling, branding, and visual design gives me a unique lens on UX. My process is holistic.
                   My execution is technical. And my focus is always on real people and real problems.</p>
            </div>
          </div>

          <div className="md:col-span-4 md:col-start-9 about-text">
            <div className="h-96 md:h-full w-full mt-16 relative parallax-element">
              <div className="absolute right-0 top-0 w-full h-full bg-gray-200">
                {/* Image Placeholder */}
                <div className="w-full h-full bg-opacity-50 flex items-center justify-center  rounded-lg">
                <img src="/images/studio-image.png" alt="Studio" className="w-full h-full object-cover  rounded-lg" />
              </div>

              </div>
            </div>
          </div>
        </div>

        <Skills />
      
      </section>
    </div>
  );
};

export default StudioLayout;